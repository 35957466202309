// @flow

import * as React from 'react'

import Hero from './Hero'

import {
  Overview,
  StickyMenuDynamic,
  OpentronsMaterial,
  Notes,
} from '../../ui-components'

import OpentronsProtocols from '../../ui-components/application-page/Protocols'

import CLEANUP_KIT from './assets/nae_hero.png'

import {
  OVERVIEW_DATA,
  MATERIAL_DESCRIPTION,
  MATERIAL_DATA,
  PROTOCOLS_DATA,
  SCROLL_MENU_DATA,
} from './cleanup-kit-data'

import * as styles from './CleanupKit.module.css'

const CONTENT_INFO = (
  <p className={styles.p}>
    With the OT-2 Nucleic Acid Extraction Workstation, you can automate your
    magnetic bead-based nucleic acid isolation and purification workflows for up
    to 24 samples. Configure your workstation with an on-deck Heater-Shaker if
    your protocol requires heating and agitation for lysis, or without a
    Heater-Shaker if your protocol does not require heating or agitation.
  </p>
)

const MENU_DATA = {
  menuData: {
    links: ['Overview', 'Workstation Components', 'Notes', 'Protocols'],
    url: '/cleanupkit/',
    workflow: 'Interested in automating your nucleic acid extraction workflow?',
  },
  gtmCategory: 'b-workstation',
  protocolGtmLabel: 'schedule-consultation',
  demoGtmLabel: 'schedule-demo',
}

export const NOTE_DATA = [
  {
    note: (
      <a
        className={styles.note_link}
        href="https://insights.opentrons.com/hubfs/Applications/Nucleic%20acid%20extraction/Microbiome%20Nucleic%20Acid%20Extraction%20with%20ZymoBIOMICS%20Kit%20on%20OT-2%20Application%20Note.pdf"
      >
        Automated microbiome extraction: bias-free, high-throughput, quality
        results with ZymoBIOMICS Kit on OT-2
      </a>
    ),
  },
  {
    note: (
      <a
        className={styles.note_link}
        href="https://insights.opentrons.com/hubfs/Applications/COVID/Automated%20Viral%20SARS-CoV-2%20Viral%20RNA%20Extraction%20Opentrons%20Beckman%20RNAdvance%20%20App%20Note.pdf"
      >
        Robust and high-throughput SARS-CoV-2 viral RNA detection, research, and
        sequencing using RNAdvance Viral and the OT-2 platform
      </a>
    ),
  },
]

export default function CleanupKit() {
  return (
    <>
      <Hero imgSrc={CLEANUP_KIT} mobileImgSrc={CLEANUP_KIT} />
      <div className={styles.application_container}>
        <StickyMenuDynamic
          {...MENU_DATA}
          scrollSpyItems={SCROLL_MENU_DATA}
          resetMenu={true}
          buttonOneText="DOWNLOAD FLYER"
          buttonOneLink="https://insights.opentrons.com/hubfs/Products/Workstations/Nucleic Acid Extraction Workstation One Sheeter.pdf"
        />
        <div className={styles.content_container}>
          <Overview content={CONTENT_INFO} {...OVERVIEW_DATA} />
          <OpentronsMaterial
            title="Workstation Components"
            description={MATERIAL_DESCRIPTION}
            materialItems={MATERIAL_DATA}
            id="workstation_components"
          />
          <Notes title="Application Notes" noteItems={NOTE_DATA} />
          <OpentronsProtocols
            {...PROTOCOLS_DATA}
            title="Protocols"
            id="protocols"
          />
        </div>
      </div>
    </>
  )
}
