import React from 'react'
import Page from '../../components/Page'
import SEO from '../../components/seo'
import CleanupKit from '../../components/CleanupKit'

const CleanupKitPage = () => (
  <Page>
    <SEO
      title="Automated DNA Cleanup | New Opentrons OT-2 Workstations"
      description="Includes everything you need to start automating SPRI bead cleanups in your lab, for under $1/sample: the robot with magnetic module, labware, beads from Omega Bio-tek, and protocols."
    />
    <CleanupKit />
  </Page>
)

export default CleanupKitPage
