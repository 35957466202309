// extracted by mini-css-extract-plugin
export var p = "CleanupKit-module--p--4OpSz";
export var h2 = "CleanupKit-module--h2--u71z9";
export var desktop_container = "CleanupKit-module--desktop_container--t59gn";
export var h4 = "CleanupKit-module--h4--SEtAG";
export var clean_up_paragraph = "CleanupKit-module--clean_up_paragraph--Bb5g7";
export var application_container = "CleanupKit-module--application_container--TPcuS";
export var container = "CleanupKit-module--container--52pac";
export var button = "CleanupKit-module--button--k96Nb";
export var mobile_image = "CleanupKit-module--mobile_image--U3SQa";
export var image = "CleanupKit-module--image--oChz3";
export var badge = "CleanupKit-module--badge--aTKbs";
export var bg_color = "CleanupKit-module--bg_color--pPiv3";
export var hero_container = "CleanupKit-module--hero_container--Q3Au4";
export var content_container = "CleanupKit-module--content_container--7qfLY";
export var shop_item_container = "CleanupKit-module--shop_item_container--N4WDS";
export var resource_span = "CleanupKit-module--resource_span--FP+rv";
export var resource_link_container = "CleanupKit-module--resource_link_container--zOm2Y";
export var icon = "CleanupKit-module--icon--ngpCd";
export var icon_image = "CleanupKit-module--icon_image--mRsep";
export var resource_link = "CleanupKit-module--resource_link--4iJ4f";
export var resource_note_container = "CleanupKit-module--resource_note_container--xYssF";
export var bold_style = "CleanupKit-module--bold_style--HDdx2";
export var italic_style = "CleanupKit-module--italic_style--KzmS3";
export var mobile_view = "CleanupKit-module--mobile_view--qaWP0";
export var desktop_view = "CleanupKit-module--desktop_view--EWT3T";
export var note_link = "CleanupKit-module--note_link--RDI0e";