export const LABWARE_URL = 'https://labware.opentrons.com/'

export const GENERIC_URL =
  'https://protocols.opentrons.com/protocol/nucleic_acid_purification_with_magnetic_beads/'

export const OVERVIEW_DATA = {
  workflowCompatibility:
    'The OT-2 Nucleic Acid Extraction Workstation can support the following workflow steps:',
  listBulletpoint: [
    'Lysis via heat and/or agitation',
    'Magnetic bead mixing and separation',
  ],
}

export const MENU_DATA = {
  menuData: {
    links: ['Overview', 'Workstation Components', 'Notes', 'Protocols'],
    url: '/cleanupkit',
    workflow: 'Interested in automating your bead cleanups?',
  },
}

export const SCROLL_MENU_DATA = [
  'overview',
  'Workstation Components',
  'notes',
  'protocols',
]

export const MATERIAL_DESCRIPTION =
  'The OT-2 Nucleic Acid Extraction Workstation includes all the hardware and consumables you need to perform nucleic acid extractions. It comes with Opentrons’ protocol development service, which provides you with a customized protocol unique to your assay. You’ll also enjoy free access to the Opentrons Protocol Designer, Python API, and Protocol Library.'

export const MATERIAL_DATA = [
  {
    imgSrc: require('../../images/application/materials/opentrons_ot2.jpg')
      .default,
    name: 'OT-2 Liquid Handling Robot',
  },
  {
    imgSrc: require('../../images/application/materials/magnetic_module.jpg')
      .default,
    name: 'Magnetic Module',
  },
  {
    imgSrc: require('./assets/3_heatshaker.png').default, //
    name: 'Heat-Shaker Module',
  },
  {
    imgSrc: require('./assets/4_p300.png').default,
    name: 'P300 Multi-Channel Pipette',
  },
  {
    imgSrc: require('./assets/5_p1000.png').default,
    name: 'P1000 Single-Channel Pipette',
  },
  {
    imgSrc: require('./assets/6_tips1000.png').default,
    name: 'Opentrons 1000 μl Filter Tips Racks (1 x 9600 tips)',
  },
  {
    imgSrc: require('./assets/7_tips200.png').default,
    name: 'Opentrons 200 μl Filter Tips Racks (2 x 9600 tips)',
  },
  {
    imgSrc: require('./assets/8_nest.png').default,
    name: 'NEST 12-Well Reservoirs 15 ml (50)',
  },
  {
    imgSrc: require('./assets/9_tough.png').default,
    name: 'Tough 0.2 mL 96-Well PCR Plates (25)',
  },
  {
    imgSrc: require('./assets/10_nest.png').default,
    name: 'NEST 2 mL Deep Well Plates (50)',
  },
  {
    imgSrc: require('./assets/11_nest.png').default,
    name: 'NEST 1-Well Reservoirs (50)',
  },
  {
    name: 'Custom protocol development (standard turnaround time)',
  },
]

export const PROTOCOLS_DATA = {
  description: '',
  hideTable: true,
  hideDisclosures: true,
  bulletListDescription:
    'Check out example protocols below, or browse all nucleic acid extraction and purification protocols <a style="color: #006fff; font-weight: 600;" href="https://protocols.opentrons.com/categories/Nucleic%20Acid%20Extraction%20%26%20Purification">here</a>',
  bulletItems: [
    {
      title: 'DNA Extraction with MACHEREY-NAGEL NucleoMag DNA Microbiome',
      url: 'https://protocols.opentrons.com/protocol/macherey-nagel-nucleomag-DNA-microbiome',
    },
    {
      title: 'DNA Extraction with MACHEREY-NAGEL NucleoMag DNA Food',
      url: 'https://protocols.opentrons.com/protocol/macherey-nagel-nucleomag-dna-food',
    },
    {
      title: 'DNA/RNA Extraction with MACHEREY-NAGEL NucleoMag Pathogen',
      url: 'https://protocols.opentrons.com/protocol/macherey-nagel-nucleomag-pathogen',
    },
    {
      title: 'DNA/RNA Extraction MACHEREY-NAGEL NucleoMag Virus',
      url: 'https://protocols.opentrons.com/protocol/macherey-nagel-nucleomag-virus',
    },
    {
      title: 'DNA Extraction with MACHEREY-NAGEL NucleoMag Tissue',
      url: 'https://protocols.opentrons.com/protocol/macherey-nagel-nucleomag-tissue',
    },
    {
      title: 'RNA Extraction with MACHEREY-NAGEL NucleoMag RNA',
      url: 'https://protocols.opentrons.com/protocol/macherey-nagel-nucleomag-rna',
    },
    {
      title:
        'DNA Extraction with Omega Bio-Tek Mag-Bind Blood and Tissue DNA HDQ 96 Kit',
      url: 'https://protocols.opentrons.com/protocol/sci-mag-bind-blood-tissue-kit',
    },
    {
      title: 'RNA Extraction with Omega Bio-Tek Mag-Bind Total RNA 96 Kit',
      url: 'https://protocols.opentrons.com/protocol/sci-omegabiotek-magbind-total-rna-96',
    },
    {
      title:
        'DNA/RNA Extraction with Omega Bio-Tek Mag-Bind Viral DNA/RNA 96 Kit',
      url: 'https://protocols.opentrons.com/protocol/sci-omegabiotek-magbind',
    },
    {
      title: 'DNA Extraction with Promega MagaZorb DNA Mini-Prep Kit',
      url: 'https://protocols.opentrons.com/protocol/sci-promega-magazorb-dna-mini-prep-kit',
    },
    {
      title:
        'RNA Extraction with Beckman Coulter RNAdvance Viral RNA Isolation Kit',
      url: 'https://protocols.opentrons.com/protocol/bc-rnadvance-viral',
    },
  ],
}
